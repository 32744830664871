.notifications-container {
    position: absolute;
    top: calc(var(--header-height) + 70px);
    right: 0;
    width: 100px;
    height: 100px;
    background: #000;
}

@media (orientation: portrait) {
    .notifications-container {
        position: fixed;
        right: 0;
        left: 0;
        top: var(--header-height);
        width: unset;
        z-index: 8;
    }
}