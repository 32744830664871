.modal-language {
    display: flex;
    flex-direction: column;
    min-width: 150px;
}
.language-modal-container {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}
.language-modal-container > button {
    flex: 1;
    text-align: center;
    padding: 5px 10px;
    border-radius: var(--border-radius-1);
    background: var(--input-bg-1-r);
}