.modal-trust {
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 10px;
}
.modal-top-text {
    display: flex;
}
.modal-top-text > span {
    font-size: 1.4rem;
    margin: 0 0 10px 0;
}
.modal-trust-container {
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: auto;
    gap: 10px;
}
.trust-headline {
    font-size: 1rem;
    font-weight: 500;
}
.modal-trust-container > ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.trust-text {
    font-size: 0.9rem;
    color: var(--color-2-r);
    background: var(--input-bg-1-r);
    border-radius: var(--border-radius-1);
    padding: 0.5em 0.75em;
}