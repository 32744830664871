.deposit-choice {
    display: flex;
    flex-direction: column;
    width: 580px;
}
.deposit-top > span {
    margin: 0;
    font-size: 1.7rem;
    color: var(--color-1-r);
}
.deposit-choice-main {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    gap: 1em;
}
.deposit-choice-main-left {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    align-content: flex-start;
}
.deposit-choice-main-right {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding-left: 1em;
    border-left: 2px solid var(--input-bg-2-r);
    gap: 0.5em;
    justify-content: flex-start;
}
.deposit-input {
    font-size: 1rem;
    border: 2px solid var(--border-color-1-r);
    padding: 0.5em 0.75em;
    background: var(--input-bg-1-r);
    border-radius: var(--border-radius-1);
    transition-duration: 0.15s;
}
.deposit-input:hover:not(:focus) {
    border: 2px solid var(--input-laberl-1-r);
}
.deposit-input:focus {
    border: 2px solid var(--primary-purple);
}
.deposit-input::placeholder {
    font-size: 1rem;
}
.deposit-submit {
    text-align: center;
    background: var(--primary-purple);
    color: var(--color-1);
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5em 0.75em;
    border-radius: var(--border-radius-1);
    cursor: pointer;
    transition-duration: 0.15s;
}
.deposit-submit:disabled {
    opacity: 0.5;
}
.deposit-submit:disabled:hover {
    opacity: 0.5;
    cursor: not-allowed;
}
.deposit-error {
    font-size: 0.9rem;
    text-align: center;
}
.deposit-error.success {
    color: #28af24;
    font-size: 0.9rem;
    text-align: center;
}
.deposit-error.error {
    color: #ee4848;
}
.deposit-error.warn {
    color: #eec748;
}
.deposit-submit:hover {
    opacity: 0.9;
}
.deposit-form-promo {
    text-align: center;
    color: #31c031;
    font-size: 0.9rem;
    flex: 1;
    display: flex;
    align-items: flex-end;
}

@media (orientation: portrait) {
    .deposit-choice-main {
        flex-direction: column;
    }
    .deposit-choice {
        width: 90vw;
    }
    .deposit-choice-main-right {
        border-left: none;
        padding-left: 0;
    }
    .deposit-choice-main-right {
        width: 100%;
    }
}