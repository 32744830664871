.main-app {
    display: flex;
    flex-direction: row-reverse;
}
.main-left {
    width: 350px;
    padding: 1em;
    border-right: 1px solid var(--border-color-1);
}
.main-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - var(--header-height));
    position: relative;
}
.main-right:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;
    height: 70px;
    width: 70px;
    background: linear-gradient(to left, var(--bg-color-1) 10px, transparent);
}
.left-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    border-radius: var(--border-radius-2);
    background: var(--block-darker-1);
}
.left-control-button {
    flex: 1;
    padding: 0.5em 0;
    text-align: center;
    border-radius: var(--border-radius-1);
    transition-duration: 0.15s;
}
.left-control-button.active {
    background: var(--block-lighter-3);
}
.left-users-container {
    display: flex;
    margin-top: 1em;
    flex-direction: column;
    gap: 1em;
    max-height: calc(100vh - var(--header-height) - 120px);
    overflow-y: auto;
}
.bet-controls {
    min-height: 190px;
    max-height: 190px;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
}
.matches-list {
    min-height: 70px;
    max-height: 70px;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: calc(100vw - 370px);
    overflow-x: scroll;
    align-items: center;
    padding: 0 1em;
    gap: 0.5em;
}

@media (orientation: portrait) {
    .main-app {
        flex-direction: column;
    }
    .bet-controls {
        flex-direction: column;
        min-height: unset;
        max-height: unset;
    }
    .graph-container {
        min-height: 400px;
        max-height: 400px;
    }
    .main-right {
        max-width: 100vw;
        overflow: hidden;
        height: 400px;
        max-height: unset;
    }
    .game-awaiting-container {
        max-height: 400px;
        min-height: 400px;
        padding: 0 5%;
    }
    .game-awaiting-container > span {
        text-align: center;
    }
    .main-left {
        max-width: 100vw;
        width: unset;
        border-right: none;
    }
    .matches-list {
        max-width: unset;
    }
    .main-right:after {
        right: -4px;
    }
    .left-users-container {
        max-height: 40vh;
        min-height: 40vh;
    }
}