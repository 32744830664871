.box {
    height: 170px;
    width: 170px;
    transform: translate(-65px, -90px);
    display: flex;
    position: relative;
    z-index: 2;
}
.box.flew {
    animation: flew 1.5s forwards;
}
@keyframes flew {
    0% {
        opacity: 1;
        transform: translate(-65px, -90px);
    }
    100% {
        opacity: 0;
        transform: translate(200px, -600px);
    }
}
.box-wrapper {
    flex: 1;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    animation: guymove 10s infinite;
}
.box-wrapper > img:nth-child(1) {
    height: 100%;
    top: 0;
    bottom: 0;
    z-index: 2;
    position: absolute;
}
.box-wrapper > img:nth-child(2) {
    position: absolute;
    height: 100%;
    z-index: 1;
    top: 117px;
    right: 129px;
    transform: scale(1, -1);
}
.box-wrapper > img:nth-child(3) {
    position: absolute;
    height: 100%;
    z-index: 1;
    top: 80px;
    right: 90px;
    animation: light 4s infinite;
}
.graph-container {
    position: relative;
    overflow: hidden;
    font-family: 'Russo One', sans-serif;
}
.stars {
    opacity: 1;
    position: absolute;
    bottom: 160px;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    background-position: center !important;
    background-image: url("./../../media/png/stars.png");
}
.graph-container::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 21px;
    width: 13px;
    background: url("./../../media/svg/ui-graph-left.svg");
    z-index: 0;
    animation: graphLeftUI 300s infinite;
}
.graph-container::after {
    position: absolute;
    content: '';
    right: 0;
    left: 0;
    bottom: 0;
    height: 21px;
    background: url("./../../media/svg/ui-graph-bottom.svg");
    animation: graphBottomUI 300s infinite;
    z-index: 0;
}
.mult-text-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    top: 0;
    left: 0;
    margin: 0 5% 0 30%;
    animation: textAppear 1s cubic-bezier(0.505, -0.020, 0.370, 1.805) forwards;
}
.mult-display {
    color: var(--primary-purple);
    font-size: 6rem;
}
.mult-flew {
    color: var(--color-2);
    font-size: 2.5rem;
    margin-top: -10px;
}
.mult-flew.visible {
    animation: textFlew 0.2s forwards;
}
.mult-flew.hidden {
    opacity: 0;
    display: none;
}
@keyframes textFlew {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.graph-moon {
    position: absolute;
    left: 80px;
    animation: moonAppear 1s forwards, moonMove 12s infinite;
}
.graph-clouds {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 159px;
    background: url("./../../media/svg/clouds.svg");
    animation: graphBottomUI 300s infinite;
}
@keyframes guymove {
    0%, 100% {
        transform: translate(0,0) scale(0.7) rotate(0);
    }
    25% {
        transform: translate(-12px,12px) scale(0.65) rotate(7deg);
    }
    50% {
        transform: translate(3px,3px) scale(0.68) rotate(-6deg);
    }
    75% {
        transform: translate(12px,-12px) scale(0.73) rotate(6deg);
    }
}
@keyframes moonMove {
    0%, 100% {
        transform: translate(0,0) scale(1) rotate(0);
    }
    25% {
        transform: translate(-12px,12px) scale(0.95) rotate(7deg);
    }
    50% {
        transform: translate(3px,3px) scale(0.97) rotate(-6deg);
    }
    75% {
        transform: translate(12px,-12px) scale(1.05) rotate(6deg);
    }
}
@keyframes textAppear {
    0% {
        opacity: 0;
        transform: scale(1) rotate(-30deg) translate(-80px, -20px);
    }
    45% {
        opacity: 1;
        transform: scale(1.1) rotate(0deg) translate(0, 0);
    }
    100% {
        transform: scale(1.2);
    }
}

@keyframes graphBottomUI {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -3000% 0;
    }
}
@keyframes graphLeftUI {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 0 4000%;
    }
}
@keyframes light {
    0%, 100% {
        opacity: 0.8;
    }
    50% {
        opacity: 0.3;
    }
}

@keyframes moonAppear {
    0% {
        top: -200px;
        opacity: 0;
    }
    100% {
        top: 30px;
        opacity: 1;
    }
}

@media (orientation: portrait) {
    .mult-text-container {
        animation: none;
        inset: 0;
        margin: auto;
        align-items: center;
        justify-content: flex-end;
        align-self: center;
    }
    .mult-display {
        font-size: 3rem;
    }
    .mult-flew {
        font-size: 1.4rem;
    }
    .graph-moon {
        height: 100px;
    }
    .box {
        transform: translate(-65px, -75px);
    }
}