.wallet-absolute {
    position: absolute;
    transition-duration: 0.15s;
    z-index: 4;
    top: 40px;
    background: var(--block-lighter-1);
    padding: 0.5em;
    border-radius: var(--border-radius-2);
    border: 1px solid var(--border-color-1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}
.wallet-absolute.opened {
    opacity: 1;
    pointer-events: unset;
}
.wallet-absolute.closed {
    opacity: 0;
    pointer-events: none;
}
.wallet-active {
    background: var(--block-lighter-4);
}
.wallet-active:hover {
    background: var(--block-lighter-4);
}

.header-money-button {
    font-size: 0.9rem;
    padding: 4px 12px 4px 8px;
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    transition-duration: 0.15s;
}
.header-money-button:hover {
    background: var(--block-lighter-4);
}
@media (orientation: portrait) {
    .wallet-absolute {
        right: 0;
    }
}