@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

:root {
    --bg-color-1: #141029;

    --block-darker-1: #100d20;

    --block-lighter-1: #1f173c;
    --block-lighter-2: #231c48;
    --block-lighter-3: #241d47;
    --block-lighter-4: #2c2458;

    --border-light: #50377f;
    --border-dark: #50377f;

    --primary-blue: #5e28fa;
    --primary-purple: #9250f5;

    --header-height: 70px;
    --border-radius-1: 8px;
    --border-radius-2: 12px;
    --border-radius-3: 16px;

    --border-color-1: rgba(255, 255, 255, 0.1);
    --border-color-1-r: rgba(0, 0, 0, 0.1);
    --border-accent-1: #403191;

    --color-1: #f4f4f6;
    --color-2: #aaaac5;
    --color-1-r: #101011;
    --color-2-r: #303036;
    --color-3-r: #505062;
    --color-4-r: #6f6f86;

    --input-bg-1-r: #edf0f8;
    --input-bg-2-r: #e4e8ec;

    --input-laberl-1-r: #b5bcc2;
}

@font-face {
    font-family: RocketText;
    src: url('./media/fonts/Text.woff2');
}
@font-face {
    font-family: RocketDisplay;
    src: url('./media/fonts/Display.woff2');
}


* {
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    display: flex;
    background: var(--bg-color-1);
    color: var(--color-1);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    display: flex;
    flex: 1;
    flex-direction: column;
}

main {
    flex: 1;
}

button {
    all: unset;
    cursor: pointer;
}
input {
    all: unset;
}
.ver-sep {
    width: 2px;
    border-radius: 1px;
    margin: 3px 0;
    background: var(--block-lighter-1);
    opacity: 0.7;
}
.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: #9193c2;
}
.icon.question {
    mask: url('./media/svg/question.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/question.svg') no-repeat center / contain;
}
.icon.wallet {
    mask: url('./media/svg/wallet.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/wallet.svg') no-repeat center / contain;
}
.icon.menu {
    mask: url('./media/svg/menu.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/menu.svg') no-repeat center / contain;
}
.icon.time {
    mask: url('./media/svg/time.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/time.svg') no-repeat center / contain;
}
.icon.close {
    mask: url('./media/svg/close.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/close.svg') no-repeat center / contain;
}
.icon.deposit {
    mask: url('./media/svg/deposit.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/deposit.svg') no-repeat center / contain;
}
.icon.withdrawal {
    mask: url('./media/svg/withdrawal.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/withdrawal.svg') no-repeat center / contain;
}
.icon.Карта {
    mask: url('./media/svg/card.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/card.svg') no-repeat center / contain;
}
.icon.USDT {
    mask: url('./media/svg/usdt.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/usdt.svg') no-repeat center / contain;
}
.icon.Bitcoin {
    mask: url('./media/svg/btc.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/btc.svg') no-repeat center / contain;
}
.icon.Ethereum {
    mask: url('./media/svg/eth.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/eth.svg') no-repeat center / contain;
}
.icon.log-out {
    background: #ff4646;
    mask: url('./media/svg/log-out.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/log-out.svg') no-repeat center / contain;
}
.icon.sound {
    height: 16px;
    width: 16px;
    margin: 2px;
    mask: url('./media/svg/sound.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/sound.svg') no-repeat center / contain;
}
.icon.trust {
    height: 16px;
    width: 16px;
    mask: url('./media/svg/trust.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/trust.svg') no-repeat center / contain;
}
.icon.trust {
    height: 16px;
    width: 16px;
    mask: url('./media/svg/trust.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/trust.svg') no-repeat center / contain;
}
.icon.support {
    height: 16px;
    width: 16px;
    mask: url('./media/svg/support.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/support.svg') no-repeat center / contain;
}
.icon.limits {
    height: 16px;
    width: 16px;
    mask: url('./media/svg/limits.svg') no-repeat center / contain;
    -webkit-mask: url('./media/svg/limits.svg') no-repeat center / contain;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

::-webkit-scrollbar {
    display: none;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (orientation: portrait) {
    :root {
        --header-height: 60px;
    }
}