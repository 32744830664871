.notification {
    display: flex;
    flex-direction: row;
    padding: 0.5em;
    border-radius: var(--border-radius-2);
    border: 1px solid var(--primary-blue);
    background: var(--block-lighter-3);
    gap: 0.5em;
    justify-content: space-between;
}
.regular-notification {
    display: flex;
    padding: 1em;
    border-radius: var(--border-radius-2);
    border: 1px solid var(--primary-blue);
    background: var(--block-lighter-3);
    text-align: center;
}
.notification:nth-child(1) {
    animation: notificationAppear 0.5s;
}
.notification-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.75em;
}
.notification-left > span:nth-child(1) {
    color: var(--color-2);
    font-size: 0.9rem;
    margin-bottom: -2px;
}
.notification-left > span:nth-child(2) {
    font-family: 'Russo One', sans-serif;
    font-size: 1.65rem;
}
.notification.won > .notification-right {
    background: linear-gradient(to left, var(--primary-purple), var(--primary-blue));
}
.notification.lost > .notification-right {
    background: linear-gradient(to left, #f57098, #ff5d5d);
}
.notification-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em 1em;
    border-radius: var(--border-radius-1);
}
.notification-right > span:nth-child(1) {
    font-family: 'Russo One', sans-serif;
    font-size: 1.65rem;
}
.notification-right > span:nth-child(2) {
    margin-top: -5px;
    font-size: 0.9rem;
}

@keyframes notificationAppear {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (orientation: portrait) {
    .notification {
        width: 320px;
    }
    .notification-right > span:nth-child(2) {
        margin-top: -5px;
        font-size: 0.8rem;
    }
    .notification-left > span:nth-child(1) {
        color: var(--color-2);
        font-size: 0.9rem;
        margin-bottom: -2px;
    }
    .regular-notification {
        width: 320px;
    }
}