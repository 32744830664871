.modal-limits {
    display: flex;
    flex-direction: column;
    width: 200px;
}
.modal-top-text {
    display: flex;
}
.modal-top-text > span {
    font-size: 1.4rem;
    margin: 0 0 10px 0;
}
.modal-limits-container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.modal-limit-container {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    border-radius: var(--border-radius-1);
    background: var(--input-bg-1-r);
}
.modal-limit-container > span:nth-child(1) {
    font-size: 0.8rem;
    color: var(--color-2-r);
}
.modal-limit-container > span:nth-child(2) {
    font-weight: 500;
}