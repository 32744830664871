.button-element {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: var(--block-lighter-1);
    border-radius: var(--border-radius-3);
    overflow: hidden;
}
.button-element-top {
    display: flex;
    padding: 0.5em 1em;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.button-element-bottom {
    display: flex;
    flex-direction: row;
    background: var(--block-lighter-4);
    padding: 1em;
    flex: 1;
    border-radius: var(--border-radius-3);
}
.button-checkboxes {
    display: flex;
    flex-direction: row;
}




/* Customize the label (the container) */
.button-checkbox {
    display: block;
    position: relative;
    padding-left: 24px;
    padding-right: 20px;
    cursor: pointer;
    font-size: 0.9rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.button-checkbox > .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: var(--block-lighter-4);
    box-shadow: 0 0 0 4px var(--bg-color-1);
    border-radius: 4px;
    transition-duration: 0.15s;
}

/* On mouse-over, add a grey background color */
.button-checkbox:hover input ~ .checkmark {
    background-color: var(--block-lighter-3);
}

/* When the checkbox is checked, add a blue background */
.button-checkbox input:checked ~ .checkmark {
    background-color: var(--primary-purple);
}

/* Create the checkmark/indicator (hidden when not checked) */
.button-checkbox > .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.button-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.button-element-bottom-left {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.button-element-sum {
    padding: 0.5em;
    border-radius: var(--border-radius-2) var(--border-radius-2) 0 0;
    background: var(--block-lighter-1);
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}
.button-element-sum > input {
    width: 50px;
    margin: 0 2em;
    text-align: center;
    font-weight: 600;
    font-size: 1.1rem;
    position: relative;
}
.button-element-sum > button {
    background: var(--block-lighter-4);
    height: 24px;
    width: 24px;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-radius: var(--border-radius-1);
    transition-duration: 0.15s;
}
.button-element-sum > button:hover {
    background: var(--block-lighter-3);
}
.button-element-sum > button > span {
    transform: translateY(-2px);
}
.button-element-sum-buttons {
    padding: 0.5em;
    background: var(--block-lighter-1);
    border-radius: 0 0 var(--border-radius-2) var(--border-radius-2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
}
.button-element-sum-buttons > button {
    padding: 3px 8px;
    font-weight: 500;
    font-size: 0.9rem;
    background: var(--block-lighter-4);
    border-radius: var(--border-radius-1);
    transition-duration: 0.15s;
}
.button-element-sum-buttons > button:hover {
    background: var(--block-lighter-3);
}
.button-element-bottom-right {
    display: flex;
    flex: 1;
    margin-left: 1em;
}
.button-element-bottom-right:has(> button:disabled) {
    opacity: 0.5;
}
.button-element-bottom-right > button {
    flex: 1;
    box-shadow: inset 0 0 20px 1px var(--primary-blue);
    background: linear-gradient(to right, var(--primary-purple), var(--primary-blue));
    text-align: center;
    border-radius: var(--border-radius-2);
    font-size: 1.4rem;
    font-weight: 500;
    transition-duration: 0.15s;
    background-position: 0%;
    background-size: 200%;
    font-family: 'Russo One', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.button-element-bottom-right > button:hover {
    background-position: 50%;
    box-shadow: inset 0 0 20px 1px var(--primary-purple);
}
.button-element-bottom-right > button.betted {
    background: linear-gradient(to right, #d7653b, #e3a951);
    box-shadow: inset 0 0 20px 1px #e35166;
}
.button-element-bottom-right > button.betted:hover {
    box-shadow: inset 0 0 20px 1px #d7653b;
}
.input-x {
    display: flex;
    flex-direction: row;
    background: var(--bg-color-1);
    padding: 4px 10px;
    gap: 4px;
    border-radius: var(--border-radius-1);
}
.input-x > span {
    transform: translateY(-1px);
    font-weight: 500;
    padding: 0 2px 0 4px;
}
.input-x > input {
    width: 30px;
    font-weight: 500;
}

.button-checkbox:has(> input:disabled) {
    opacity: 0.5;
}

@media (orientation: portrait) {
    .button-element-bottom {
        padding: 0.5em;
        font-size: 0.7rem;
    }
    .button-element-bottom-right > button {
        font-size: 1.2rem;
    }
    .button-element-bottom-right {
        margin-left: 0.5em;
    }
    .button-checkbox-label {
        font-size: 0.8rem;
    }
}