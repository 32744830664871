.deposit-modal {
    min-width: 350px;
}
.deposit-top > span {
    margin: 0;
    font-size: 1.7rem;
    color: var(--color-1-r);
}
.deposit-details {
    display: flex;
    flex-direction: column;
}
.deposit-details-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--border-color-1-r);
    padding: 10px 0;
}
.deposit-details-top > span {
    font-size: 1.5rem;
    font-weight: 500;
}
.deposit-details-top > img {
    height: 64px;
    width: 64px;
}
.deposit-details-info {
    display: flex;
    flex-direction: column;
}
.deposit-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    padding: 10px 0;
    border-bottom: 1px solid var(--border-color-1-r);
}
.deposit-detail-value {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.deposit-detail-value > span {
    background: var(--input-bg-1-r);
    align-self: flex-start;
    padding: 2px 6px;
    border-radius: var(--border-radius-1);
    font-size: 0.9rem;
}
.copy-button {
    font-size: 0.9rem;
    color: var(--color-1-r);
    padding: 2px 6px;
    border-radius: var(--border-radius-1);
    opacity: 0.5;
    transition-duration: 0.2s;
}
.copy-button.true {
    color: green;
}
.copy-button.true:hover {
    opacity: 0.5;
}
.copy-button:hover {
    opacity: 1;
}
.check-payment {
    background: #44e544;
    padding: 8px;
    border-radius: var(--border-radius-1);
    text-align: center;
    transition-duration: 0.2s;
}
.check-payment:hover {
    background: #2ecb2e;
}
.deposit-error-check {
    text-align: center;
    color: #f54848;
    margin-top: 4px;
    font-size: 0.9rem;
}
.deposit-promo {
    text-align: center;
    font-size: 0.9rem;
    color: #1abe1a;
    margin-top: 4px;
}
@media (orientation: portrait) {
    .deposit-modal {
        min-width: unset;
    }
    .deposit-detail-value {
        flex-direction: column;
        align-items: flex-start;
        gap: 0em;
    }
    #payment-number {
        overflow-x: auto;
        max-width: 90vw;
    }
}