.deposit-choice-element {
    flex: 34%;
    height: 80px;
    border-radius: var(--border-radius-1);
    padding: 1.2em;
    display: flex;
    align-items: center;
    transition-duration: 0.15s;
    background: var(--color-4-r);
    cursor: pointer;
    gap: 0.5em;
    font-weight: 500;
    position: relative;
    overflow: hidden;
}
.deposit-choice-percent {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 0.7rem;
    border-radius: 0.5em 0 0 0;
    color: var(--color-1);
    padding: 0.1em 0.5em;
    background: #000;
}
.deposit-choice-element:hover {
    background: var(--color-3-r);
}
.deposit-choice-element:has(> .deposit-choice-checkbox:checked) {
    background: var(--primary-purple);
}
.deposit-choice-element > i {
    background: var(--color-1-r);
    height: 28px;
    width: 28px;
}
.deposit-choice-element:has(> .deposit-choice-checkbox:checked) > i {
    background: var(--color-1);
}
.deposit-choice-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.deposit-choice-element > span:nth-child(2) {
    position: absolute;
    top: 0;
    color: var(--color-1);
    left: 0;
    background: var(--color-1-r);
    font-size: 0.7rem;
    border-radius: 0 0 0.5em 0;
    padding: 0.1em 0.5em;
}