.how-to-play-absolute {
    position: absolute;
    transition-duration: 0.15s;
    z-index: 4;
    top: 40px;
    background: var(--block-lighter-1);
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-2);
    border: 1px solid var(--border-color-1);
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    gap: 0.5em;
    padding: 0.5em;
}
.how-to-element {
    display: flex;
    padding-bottom: 0.5em;
    flex-direction: column;
    background: var(--block-lighter-4);
    border-radius: var(--border-radius-1);
}
.how-to-element > img {
    height: 130px;
}
.how-to-element > span {
    margin: -2em 1em 0 1em;
    font-size: 0.9rem;
    color: var(--color-1);
    font-weight: 500;
}
.how-to-play-absolute.opened {
    opacity: 1;
    pointer-events: unset;
}
.how-to-play-absolute.closed {
    opacity: 0;
    pointer-events: none;
}
.how-to-active {
    background: var(--block-lighter-4);
}
.how-to-active:hover {
    background: var(--block-lighter-4);
}

@media (orientation: portrait) {
    .how-to-play-absolute {
        right: 0;
        transform: translateX(50%);
    }
}