.notification-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    z-index: 6;
    top: var(--header-height);
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
}