.main-element-container {
    width: 100%;
    height: 100%;
}
.game-awaiting-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5%;
}
.game-awaiting-container > img {
    height: 50%;
}
.game-awaiting-container > span {
    font-weight: 500;
    font-size: 2rem;
}
.timeline-container {
    display: flex;
    width: 400px;
    height: 3px;
    border-radius: 2px;
    background: var(--block-lighter-3);
    overflow: hidden;
}
.timeline-inner {
    animation: timeline 15s linear forwards;
    height: 100%;
    background: var(--primary-blue);
}

@keyframes timeline {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

@media (orientation: portrait) {
    .timeline-container {
        width: 300px;
    }
}