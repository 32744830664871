.bets-await {
    display: flex;
    justify-content: center;
    align-items: center;
}
.bets-await {
    font-size: 1.2rem;
    font-weight: 500;
}
.bets-info {
    display: flex;
    flex-direction: row;
    gap: 1em;
}
.total-bets {
    display: flex;
    flex-direction: column;
    padding: 0.3em 0.6em;
    background: var(--block-lighter-4);
    border-radius: var(--border-radius-1);
    border: 1px solid var(--border-accent-1);
}
.total-bets > span:nth-child(1) {
    color: var(--color-2);
    font-size: 0.9rem;
}
.total-bets > span:nth-child(2) {
    font-weight: 700;
    font-size: 1.1rem;
}
.bets-info-game-id {
    border-radius: var(--border-radius-1);
    border: 1px solid var(--border-accent-1);
    padding: 0.3em 0.6em;
    background: var(--block-lighter-4);
    display: flex;
    flex-direction: column;
}
.bets-info-game-id > span:nth-child(1) {
    font-size: 0.9rem;
    color: var(--color-2);
}
.bets-info-game-id > span:nth-child(2) {
    font-size: 1.1rem;
    font-weight: 700;
    color: #45f0ff;
}

@media (orientation: portrait) {
    .total-bets {
        flex: 1;
    }
    .bets-info-game-id {
        flex: 1;
    }
}