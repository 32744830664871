.modal-backdrop {
    position: fixed;
    z-index: 5;
    transition-duration: 0.2s;
    inset: 0;
    background: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-backdrop.closed {
    opacity: 0;
    pointer-events: none;

}
.modal-backdrop.opened {
    opacity: 1;
    pointer-events: unset;
}
.modal-container {
    padding: 1em;
    color: var(--color-1-r);
    position: relative;
    display: flex;
    background: #fff;
    border-radius: var(--border-radius-3);
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
}
.modal-close {
    position: absolute;
    top: 1em;
    right: 1em;
    padding: 3px;
    background: var(--input-bg-1-r);
    border-radius: var(--border-radius-1);
    transition-duration: 0.15s;
}
.modal-close:hover {
    background: var(--input-bg-2-r);
}
.modal-close > i {
    height: 18px;
    width: 18px;
    background: var(--input-laberl-1-r) !important;
}
.auth-top-text-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.7em;
    margin-bottom: 0.5em;
    border-bottom: 2px solid var(--input-bg-2-r);
    gap: 2px;
}
.auth-top-text-container > h1 {
    margin: 0;
    font-size: 1.7rem;
    color: var(--color-1-r);
}
.auth-top-text-container > span {
    font-size: 0.85rem;
    color: var(--color-2-r);
    font-weight: 500;
}
.auth-form {
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    min-width: 350px;
}
.auth-next-button {
    font-size: 0.9rem;
    background: linear-gradient(to right, var(--primary-blue), var(--primary-purple));
    padding: 0.7em 1.2em;
    text-align: center;
    color: var(--color-1);
    font-weight: 600;
    border-radius: var(--border-radius-1);
    transition-duration: 0.25s;
    background-position: 100%;
    background-size: 175%;
}
.auth-next-button:hover {
    background-position: 25%;
}
.auth-error {
    color: #fd4747;
    text-align: center;
    font-size: 0.9rem;
}
.auth-bottom {
    font-size: 0.8rem;
    color: var(--color-2-r);
    align-self: center;
}
.auth-bottom > button {
    margin-left: 4px;
    color: var(--primary-blue);
}

@media (orientation: portrait) {
    .auth-form {
        min-width: 300px;
        max-width: 300px;
    }
    .modal-container {
        max-width: 98vw;
    }
}