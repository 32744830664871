.my-bet-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em;
    --width-user-element-ready: calc(100% - 50px);
    font-size: 0.9rem;
    align-items: center;
    gap: 3px;
    background: linear-gradient(to right, var(--block-lighter-1), var(--block-lighter-3));
    border-radius: var(--border-radius-2);
}
.my-bet-element.won {
    border: 1px solid rgba(15, 255, 15, 0.5);
}
.my-bet-element.lost {
    border: 1px solid rgba(255, 15, 15, 0.5);
}
.my-bet-element > * {
    overflow: hidden;
    text-overflow: ellipsis;
}

.my-pfp {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7d3fda;
    border-radius: var(--border-radius-1);
}
.my-game-id {
    max-width: 75px;
    min-width: 75px;
    opacity: 0.7;
}
.my-word {
    flex: 1;
    text-align: end;
}
.my-multiplier {
    flex: 1;
    text-align: center;
    padding: 2px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.my-multiplier > span {
    border-radius: var(--border-radius-1);
    padding: 2px 0;
    width: 48px;
}
.my-multiplier.default > span {
    background: #3869c5;
}
.my-multiplier.first > span {
    background: #3838c5;
}
.my-multiplier.second > span {
    background: #7e45c4;
}
.my-multiplier.third > span {
    background: #ae50d3;
}
.my-multiplier.forth > span {
    background: #c0557e;
}
.my-multiplier.lost > span {
    background: #ff4646;
}
.my-result {
    flex: 1.75;
    text-align: start;
}
.my-result.won {
    color: #2aff2a;
}
.my-result.lost {
    color: #ff4646;
}

.my-bet-element.isActive {
    border: 1px solid rgba(15, 19, 255, 0.5);
}
.my-bet-element.isActive > .my-multiplier > span {
    background: var(--block-lighter-4);
}
.my-bet-element.isActive > .my-result {
    color: var(--color-1);
}