.top-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em;
    --width-user-element-ready: calc(100% - 50px);
    font-size: 0.9rem;
    align-items: center;
    gap: 3px;
    border: 1px solid var(--border-color-1);
    background: linear-gradient(to right, var(--block-lighter-1), var(--block-lighter-3));
    border-radius: var(--border-radius-2);
}
.top-element > * {
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-element > .pfp {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7d3fda;
    border-radius: var(--border-radius-1);
}
.top-element > .name {
    max-width: 56px;
    min-width: 56px;
    opacity: 0.7;
}
.top-element > .bet {
    flex: 1;
    text-align: start;
}
.top-element > .multiplier {
    flex: 1;
    text-align: center;
    padding: 2px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-element > .multiplier > span {
    border-radius: var(--border-radius-1);
    padding: 2px 0;
    width: 48px;
    background: #c836de;
}

.top-element > .win {
    flex: 1;
    text-align: start;
    color: gold;
}