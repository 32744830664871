.auth-input {
    background: var(--input-bg-1-r);
    padding: 0.7em 1.2em;
    font-size: 0.9rem;
    border-radius: var(--border-radius-1);
    border: 2px solid var(--input-bg-1-r);
    transition-duration: 0.15s;
}
.auth-input::placeholder {
    color: var(--input-laberl-1-r);
}
.auth-input:hover {
    border: 2px solid var(--input-bg-2-r);
}
.auth-input:focus {
    border: 2px solid var(--primary-purple);
}
.auth-input:focus:hover {
    border: 2px solid var(--primary-purple);
}