.user-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em;
    --width-user-element-ready: calc(100% - 50px);
    font-size: 0.9rem;
    align-items: center;
    gap: 3px;
    border: 1px solid var(--border-color-1);
    background: linear-gradient(to right, var(--block-lighter-1), var(--block-lighter-3));
    border-radius: var(--border-radius-2);
}
.user-element.picked {
    border: 1px solid var(--border-accent-1);
    background: var(--block-lighter-4);
}
.user-element > * {
    overflow: hidden;
    text-overflow: ellipsis;
}
.user-pfp {
    height: 25px;
    width: 25px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7d3fda;
    border-radius: var(--border-radius-1);
}
.user-username {
    max-width: 75px;
    min-width: 75px;
    opacity: 0.7;
}
.user-init-sum {
    flex: 1;
    text-align: end;
}
.user-multiplier {
    flex: 1;
    text-align: center;
    padding: 2px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-multiplier > span {
    border-radius: var(--border-radius-1);
    padding: 2px 0;
    width: 48px;
}
.user-multiplier.default > span {
    background: #3869c5;
}
.user-multiplier.first > span {
    background: #3838c5;
}
.user-multiplier.second > span {
    background: #7e45c4;
}
.user-multiplier.third > span {
    background: #ae50d3;
}
.user-multiplier.forth > span {
    background: #c0557e;
}
.user-win-sum {
    flex: 1;
    text-align: start;
    color: gold;
}
.user-element.not-picked > .user-multiplier > span {
    background: var(--block-lighter-1);
    color: var(--color-2);
}
.user-element.not-picked > .user-win-sum {
    color: var(--color-2);
}