.game-value-element {
    display: flex;
    flex-direction: column;
    padding: 4px 10px;
    border-radius: var(--border-radius-1);
    border: 1px solid var(--border-color-1);
}
.game-value-element.default {
    background: #4848da;
}
.game-value-element.second {
    background: #7148da;
}
.game-value-element.third {
    background: #9848da;
}
.game-value-element.forth {
    background: #da48a7;
}
.game-value-element > span:nth-child(1) {
    font-size: 0.6rem;
    color: var(--color-1);
    margin-bottom: -3px;
}
.game-value-element > span:nth-child(2) {
    font-size: 1rem;
    font-weight: 600;
}