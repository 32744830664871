.header-button.menu.active {
    background: var(--block-lighter-4);
}
.header-menu-container {
    position: relative;
}
.header-menu-absolute {
    width: 180px;
    z-index: 4;
    background: var(--block-lighter-1);
    position: absolute;
    display: flex;
    right: 0;
    transition-duration: 0.2s;
    flex-direction: column;
    gap: 0.5em;
    top: 40px;
    padding: 0.5em;
    border-radius: var(--border-radius-2);
    border: 1px solid var(--border-color-1);
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
}
.header-menu-absolute.closed {
    opacity: 0;
    pointer-events: none;
}
.header-menu-absolute.open {
    opacity: 1;
    pointer-events: unset;
}
.header-menu-top {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.header-user-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.header-user-id {
    display: flex;
    flex-direction: column;
}
.header-user-id > span:nth-child(1) {
    color: var(--color-2);
    font-size: 0.8rem;
}
.header-user-id > span:nth-child(2) {
    font-size: 0.68rem;
}
.header-user-data > span {
    font-weight: 500;
}
.header-menu-top-logout {
    background: #fa5151;
    text-align: center;
    flex: 1;
    padding: 0.3em;
    font-weight: 500;
    font-size: 0.9rem;
    border-radius: var(--border-radius-1);
    transition-duration: 0.15s;
}
.header-menu-top-logout:hover {
    background: #d74343;
}
.header-user-pfp {
    background: var(--primary-purple);
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-radius: var(--border-radius-1);
}
.header-menu-main {
    padding: 0.5em;
    background: var(--block-lighter-4);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.heaer-menu-link {
    color: var(--color-1);
    text-decoration: none;
    font-size: 1rem;
    transition-duration: 0.15s;
    display: flex;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
.heaer-menu-link:hover {
    color: var(--color-2);
}
