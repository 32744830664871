header {
    height: var(--header-height);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5%;
    align-items: center;
    border-bottom: 1px solid var(--border-color-1);
}
.header-left, .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}
.button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    border: 5px solid var(--block-darker-1);
    border-radius: var(--border-radius-2);
    background: var(--block-darker-1);
    position: relative;
}
.header-button {
    transition-duration: 0.15s;
    font-size: 0.9rem;
    height: 30px;
    padding: 0 0.7em 0 0.5em;
    background: var(--block-lighter-3);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}
.header-button:hover {
    background: var(--block-lighter-1);
}
.header-button.game-id {
    display: flex;
    padding: 0 0.7em;
}
.header-button.game-id > span:nth-child(1) {
    color: var(--color-2);
}
.header-button.game-id > span:nth-child(2) {
    color: #6ef1f6;
    font-weight: 600;
}
.header-button.game-id:hover {
    background: var(--block-lighter-3);
}
.header-button.auth {
    padding: 0 0.7em;
}
.header-button.menu {
    padding: 0 0.5em;
}
.sound-btn {
    padding: 0 0.5em 0 0.3em;
    position: relative;
}
.sound-off-line {
    position: absolute;
    height: 2px;
    width: 20px;
    background: #fc6464;
    transform: rotate(45deg);
}
@media (orientation: portrait) {
    .button-wrapper:has(> .header-button.game-id) {
        display: none;
    }
    .button-wrapper:has(>.how-to-play-container) {
        display: flex;
    }
    .button-wrapper:has(>.how-to-play-container) > .how-to-play-container > .header-button {
        padding-right: 0.5em;
    }
    .button-wrapper:has(>.how-to-play-container) > .how-to-play-container > .header-button > span  {
        display: none;
    }
    .header-button.auth:nth-child(3) {
        display: none;
    }
    .ver-sep.auth {
        display: none;
    }
    .button-wrapper:has(> .sound-btn) {
        display: none;
    }
    .header-right {
        gap: 0.5em;
    }
    .header-button {
        font-size: 0.75rem;
    }
    .header-left > img {
        width: 120px;
        margin-top: 4px;
    }
}